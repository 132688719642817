/**
* Generated automatically at built-time (2024-12-05T06:26:46.162Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "cantinetta-vino-black-friday",templateKey: "sites/69-f2fb3384-9f77-441b-ba18-0cdbe8e7d5d2"};